import * as React from "react"
import * as styles from './styles.module.scss'
import PhoneIcon from '../../images/icons/ic_phone.png'
import OfficeIcon from '../../images/icons/ic_building_house.png'
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvent } from 'react-leaflet'
import { LatLng } from "leaflet"

const OurOfficeComponent = () => {
  return (
    <div id={'our-office'} className={styles.container}>
      <h2 className={styles.label}>
        Office
      </h2>

      <div className={styles.texts}>
        <div className={styles.textRow}>
          <div>
            <img src={PhoneIcon} alt={'Phone Icon'} />
          </div>

          <p>
            (031) 59175172
          </p>
        </div>

        <div className={styles.textRow}>
          <div>
            <img src={OfficeIcon} alt={'Office Icon'} />
          </div>

          <p>
            Jl. Mojo 3A No. 8A RT. 003 RW. 005, Kel. Mojo, Kec.<br />Gubeng, Kota Surabaya, Prop. Jawa Timur
          </p>
        </div>
      </div>

      <div className={styles.mapContainer}>
        <MapContainer
          style={{ height: '100%', width: '100%' }}
          center={[-7.269384, 112.7662971]}
          zoom={18}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={[-7.269384, 112.7662971]}>
            <Popup>
              ASEVEN Office
            </Popup>
          </Marker>
        </MapContainer>
      </div>

      <a className={styles.openMaps} href="https://goo.gl/maps/JP2Zk87njhbVN52A9" target="_blank">
        Buka di Google Maps
      </a>

    </div>
  )
}

export default OurOfficeComponent