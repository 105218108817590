import Subservice from "./Subservice";

interface Props {
  title: string;
  description: string;
  illustration: any;
  subservices?: Subservice[];
}

class OurService {
  private readonly _title: string;
  private readonly _description: string;
  private readonly _illustration: any;
  private readonly _subservices: Subservice[];

  constructor(props: Props) {
    const { title, description, illustration, subservices } = props;

    this._title = title;
    this._description = description;
    this._illustration = illustration;
    this._subservices = subservices ?? [];
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get illustration() {
    return this._illustration;
  }

  get subservices() {
    return this._subservices;
  }
}

export default OurService;