import { navigate } from 'gatsby';
import * as React from 'react';
import HeroParallaxComponent from './Parallax/HeroParallaxComponent';
import * as styles from './styles.module.scss';

const HeroComponent = () => (
  <>
    <HeroParallaxComponent />

    <div className={styles.container}>
      <div className={styles.left}>
        <h2 className={styles.title}>
          Solving Complex Problem with Technology
        </h2>

        <p className={styles.description}>
          Here at Aseven Indonesia we build technology to solve your business challenge. We build web and apps to support your day-to-day operation running smoothly and increase productivity to leverage your business.
        </p>

        <div className={styles.btnStack}>
          <button
            className={styles.learnMore}
            onClick={() => navigate('/#our-services')}
          >
            Learn More
          </button>

          <button
            className={styles.contactUs}
            onClick={() => navigate('/#contact-us')}
          >
            Contact Us
          </button>
        </div>
      </div>

      <div className={styles.right}>

      </div>
    </div>
  </>
);

export default HeroComponent;