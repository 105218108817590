import { navigate } from "gatsby";
import * as React from "react";
import { useEffect, useState } from "react";
import ViewIcon from '../../../../images/icons/ic_view_project.png';
import OurWork from "../../Models/OurWork";
import * as styles from './styles.module.scss';

interface Props {
  work: OurWork;
}

const WorkComponent = (props: Props) => {
  const { slug, title, logo, background } = props.work;

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [])

  return (
    <div
      className={styles.card}
      style={{
        background: `url(${background})`,
        backgroundSize: isMobile ? 'auto 325px, cover' : '420px 460px, cover',
        backgroundRepeat: 'no-repeat'

      }}
    >
      <div className={styles.logoCard}>
        <img src={logo} alt={`Logo of ${title}`} className={styles.logo} />
      </div>

      <div className={styles.inner}>
        <button onClick={() => {
          if (props.work.hasDetailScreen) {
            navigate(`/our-works/${slug}`)
          }
        }}>
          <div>
            {`View ${title}`}

            <img src={ViewIcon} />
          </div>
        </button>
      </div>
    </div>
  );
}

export default WorkComponent;