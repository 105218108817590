import * as React from "react"
import Subservice from "../Models/Subservice";
import * as styles from './styles.module.scss'

interface Props {
  subservice: Subservice;
}

const SubserviceCardComponent = (props: Props) => {
  const { icon, title, description } = props.subservice;

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div className={styles.iconCard}>
          <img src={icon} />
        </div>
      </div>

      <div className={styles.right}>
        <h6 className={styles.title}>
          {title}
        </h6>

        <p className={styles.description}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default SubserviceCardComponent;