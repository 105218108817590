import * as React from "react"
import * as styles from './styles.module.scss'
import OurActivity from "./Models/OurActivity";
import OurActivityIcon1 from '../../images/illustrations/our-activity-1.png'
import OurActivityIcon2 from '../../images/illustrations/our-activity-2.png'
import OurActivityIcon3 from '../../images/illustrations/our-activity-3.png'
import OurActivityIcon4 from '../../images/illustrations/our-activity-4.png'
import SectionTitleComponent from "../Common/Components/SectionTitle/SectionTitleComponent";

const activities = [
  new OurActivity({
    title: 'Discussion for what you need',
    description: 'Understand your workflow, workspace, and the obstacle you face.',
    illustration: OurActivityIcon1
  }),
  new OurActivity({
    title: 'Planning and design the needed system',
    description: 'Listing system specification, project timeline, create business flow, and sketching initial interface.',
    illustration: OurActivityIcon2
  }),
  new OurActivity({
    title: 'Develop and Deliver',
    description: 'Assigning developer to needed position team based on project requirement. Deliver and deploy the system on schedule.',
    illustration: OurActivityIcon3
  }),
  new OurActivity({
    title: 'Monitoring and Support',
    description: 'Monitor the system performance in your daily usage, and providing bug reporting and bug fixing.',
    illustration: OurActivityIcon4
  }),
];

const OurActivitiesComponent = () => {
  return (
    <div id={'what-we-do'} className={styles.container}>
      <SectionTitleComponent
        title={'What We Do'}
        subtitle={'Let us describe what we do to accomplish our client goal '}
      />

      <div className={styles.list}>
        {
          activities.map((activity, index) => {
            const { title, description, illustration } = activity

            return (
              <div className={styles.item}>
                <div className={styles.texts}>
                  <div className={styles.number}>
                    <p>
                      {index + 1}.
                    </p>
                  </div>

                  <h3 className={styles.title}>
                    {title}
                  </h3>

                  <p className={styles.description}>
                    {description}
                  </p>
                </div>

                <img
                  src={illustration}
                  alt={`Illustration of ${title}`}
                  className={styles.illustration}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default OurActivitiesComponent;