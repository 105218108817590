import * as React from 'react'
import * as styles from './styles.module.scss'
import Dots from '../../images/illustrations/vertical-dots.png'
import Capsule from '../../images/illustrations/capsule.png'
import SectionTitleComponent from '../Common/Components/SectionTitle/SectionTitleComponent'
import MailIcon from '../../images/icons/ic_mail.png'
import WhatsAppIcon from '../../images/icons/ic_whatsapp.png'

const ContactUsComponent = () => {
  return (
    <div id={'contact-us'} className={styles.container}>
      <div className={styles.card}>
        <div className={styles.inner}>
          <SectionTitleComponent
            title={'Get started now with us!'}
            subtitle={'Feel free to ask us on email or WhatsApp'}
          />

          <div className={styles.action}>
            <a
              className={styles.emailUs}
              href={'mailto:business@aseven.co.id'}
              target={'_blank'}
            >
              <div>
                <img src={MailIcon} />

                {'Email Us'}
              </div>
            </a>

            <a
              className={styles.chatUs}
              href={'https://wa.me/message/4HIFFREOEKBOE1'}
              target={'_blank'}
            >
              <div>
                <img src={WhatsAppIcon} />

                {'Message Us on WhatsApp'}
              </div>
            </a>
          </div>

        </div>

        <img
          src={Dots}
          alt={`Dots Decoration`}
          className={styles.dots}
        />

        <img
          src={Capsule}
          alt={`Capsule Illustration`}
          className={styles.capsule}
        />
      </div>
    </div>
  )
}

export default ContactUsComponent;