import * as React from "react"
import * as styles from './styles.module.scss';
import { useState, useRef, useEffect } from "react";

import HeroDots from '../../../images/illustrations/hero-dots.png';
import PieChartIllustration from '../../../images/illustrations/pie_chart.png'
import CheckIllustration from '../../../images/illustrations/parallax_check.png'
import ZoomIllustration from '../../../images/illustrations/zoom.png'
import HeroIllustration from '../../../images/illustrations/hero-illustration.png';
import VisibilityComponent from "../../Components/Visibility/VisibilityComponent";

const HeroParallaxComponent = () => {
  const [position, setPosition] = useState(0);
  const positionRef = useRef<number>(0);

  const [pieTop, setPieTop] = useState<number>();
  const [personTop, setPersonTop] = useState<number>();
  const [checkTop, setCheckTop] = useState<number>();
  const [zoomTop, setZoomTop] = useState<number>();

  useEffect(() => {
    positionRef.current = position;
  })
  const prevPosition = positionRef.current;

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [])

  const onScroll = () => {
    const newPosition = window.pageYOffset;
    setPosition(newPosition);
  };

  useEffect(() => {
    setPieTop((-10) - (position * 0.7));
    setPersonTop(140 - (position * 0.9));
    setCheckTop(320 - (position * 1.1));
    setZoomTop(550 - (position * 1.3));

  }, [position]);

  return (
    <div className={styles.cream}>
      <img src={HeroDots} className={styles.dots} alt={'Dots Illustration'} />

      <img
        src={PieChartIllustration}
        className={styles.pieChart}
        style={{
          top: pieTop + 'px'
        }}
        alt={'Pie Chart Illustration'}
      />

      <img
        className={styles.person}
        src={HeroIllustration}
        style={{
          top: personTop + 'px'
        }}
        alt={'Illustration'}
      />

      <img
        src={CheckIllustration}
        className={styles.check}
        style={{
          top: checkTop + 'px'
        }}
        alt={'Check Illustration'}
      />

      <img
        src={ZoomIllustration}
        className={styles.zoom}
        style={{
          top: zoomTop + 'px'
        }}
        alt={'Zoom Illustration'}
      />
    </div>

  );
};

export default HeroParallaxComponent;