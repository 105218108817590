import * as React from "react"
import * as styles from './styles.module.scss'
import SectionTitleComponent from "../../Common/Components/SectionTitle/SectionTitleComponent";
import WorkComponent from "./Work/WorkComponent";
import OurWorks from "../Models/OurWorks";

const OurWorksComponent = () => (
  <div id={'our-works'} className={styles.container}>
    <div className={styles.title}>
      <SectionTitleComponent
        title={'Our Works'}
        subtitle={'Here’s what we do so far'}
      />
    </div>

    <div className={styles.list}>
      <div className={styles.listInner}>
        {
          Object.keys(OurWorks).map(key => (
            <WorkComponent work={OurWorks[key]} />
          ))
        }
      </div>
    </div>
  </div>
)

export default OurWorksComponent;