import * as React from "react"
import * as styles from './styles.module.scss'
import OurService from "../Models/OurService"
import SubserviceCardComponent from "../SubserviceCard/SubserviceCardComponent";
import VisibilityComponent from "../../Common/Components/Visibility/VisibilityComponent";
import WebIcon from '../../../images/icons/our_services/ic_web.png';
import AndroidIcon from '../../../images/icons/our_services/ic_android.png';
import IOSIcon from '../../../images/icons/our_services/ic_ios.png';


interface Props {
  service: OurService
}

const OurServiceComponent = (props: Props) => {
  const { title, description, illustration, subservices } = props.service;

  return (
    <div className={styles.card}>
      <img
        className={styles.illustration}
        src={illustration}
      />

      <div className={styles.texts}>
        <h6 className={styles.title}>
          {title}
        </h6>

        <p className={styles.description}>
          {description}
        </p>

        <VisibilityComponent isVisible={title.toLowerCase() === 'software solution'}>
          <div className={styles.platforms}>
            <p className={styles.title}>
              Platforms Available
            </p>

            <div className={styles.list}>
              <div className={styles.item}>
                <img src={WebIcon} />
                <p>Web</p>
              </div>
              <div className={styles.item}>
                <img src={AndroidIcon} />
                <p>Android</p>
              </div>
              <div className={styles.item}>
                <img src={IOSIcon} />
                <p>iOS</p>
              </div>
            </div>
          </div>
        </VisibilityComponent>

        <div className={styles.subservices}>
          {
            subservices.map(subservice => (
              <SubserviceCardComponent subservice={subservice} />
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default OurServiceComponent;