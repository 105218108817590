interface Props {
  title: string;
  description: string;
  illustration: any;
}

class OurActivity {
  private readonly _title: string;
  private readonly _description: string;
  private readonly _illustration: any;

  constructor(props: Props) {
    const { title, description, illustration } = props;

    this._title = title;
    this._description = description;
    this._illustration = illustration;
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get illustration() {
    return this._illustration;
  }
}

export default OurActivity;