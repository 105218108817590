import * as React from "react"
import Layout from "../../Modules/Common/Components/layouts/en"
import Seo from "../../Modules/Common/Components/seo"
import HeroComponent from "../../Modules/Hero/HeroComponent";
import OurActivitiesComponent from "../../Modules/OurActivities/OurActivitiesComponent";
import OurServicesComponent from "../../Modules/OurServices/OurServicesComponent";
import OurWorksComponent from "../../Modules/OurWorks/Screens/OurWorksComponent";
import ContactUsComponent from "../../Modules/ContactUs/ContactUsComponent";
import OurOfficeComponent from "../../Modules/OurOffice/OurOfficeComponent";

const IndexPage = () => {

  return (
    <Layout>
      <Seo title={'Aseven Indonesia | Business Consulting and Software Solution'} />

      <HeroComponent />

      <OurServicesComponent />

      <OurWorksComponent />

      <OurActivitiesComponent />

      <ContactUsComponent />

      {/* <AboutComponent /> */}

      <OurOfficeComponent />
    </Layout>
  )
}

export default IndexPage
