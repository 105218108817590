import * as styles from './styles.module.scss'
import * as React from "react";

interface Props {
  title: string;
  color?: string;
  subtitle?: string;
}

const SectionTitleComponent = (props: Props) => {
  const { title, color, subtitle } = props;

  return (
    <>
      <h2 
        className={styles.title}
        style={{
          color: color
        }}
      >
        {title}
      </h2>

      <h3 className={styles.subtitle}>
        {subtitle}
      </h3>
    </>
  );
};

export default SectionTitleComponent;