import * as React from "react"
import * as styles from './styles.module.scss'
import SectionTitleComponent from "../Common/Components/SectionTitle/SectionTitleComponent";
import OurService from "./Models/OurService";
import OurServiceComponent from "./OurService/OurServiceComponent";
import OurServiceIcon1 from "../../images/illustrations/our-service-1.png";
import OurServiceIcon2 from "../../images/illustrations/our-service-2.png";
import BusinessIdeationIcon from "../../images/icons/our_services/ic_business_ideation.png";
import BusinessOptimizationIcon from "../../images/icons/our_services/ic_business_optimization.png";
import SoftwareImplementationIcon from "../../images/icons/our_services/ic_software_implementation.png";
import ReportingIcon from "../../images/icons/our_services/ic_reporting.png";
import OurServicesDots from '../../images/illustrations/our_services_dots.png';

const services = [
  new OurService({
    title: 'Business Consulting',
    description: 'Refine and expand your business process and requirements with our expertise, thus creating a solid concept to achieve your needs and goals.',
    illustration: OurServiceIcon1,
    subservices: [
      {
        icon: BusinessIdeationIcon,
        title: 'Business Ideation',
        description: 'Your ideas meet our insight and experience in technology and related field to create a development-ready concept.'
      },
      {
        icon: BusinessOptimizationIcon,
        title: 'Business Optimization',
        description: 'Improve and optimize your existing business process with our technology solutions to achieve higher productivity.'
      }
    ]
  }),
  new OurService({
    title: 'Software Solution',
    description: 'Transform your manual business process to automated and systematic business process to reduce human error and time-consuming operational.',
    illustration: OurServiceIcon2,
    subservices: [
      {
        icon: SoftwareImplementationIcon,
        title: 'Software Implementation',
        description: 'Implement your business process on selected platforms to fulfill the business requirement and user needs.'
      },
      {
        icon: ReportingIcon,
        title: 'Reporting',
        description: 'Data produced from the business process will be converted to useful information for your following strategy and decision.'
      }
    ]
  }),
];

const OurServicesComponent = () => {


  return (
    <div id={'our-services'} className={styles.container}>
      <div className={styles.inner}>
        <SectionTitleComponent
          title={'Our Services'}
          color={'white'}
          subtitle={'We serve for your business'}
        />

        <div className={styles.row}>
          <div className={styles.list}>
            {
              services.map(service => (
                <OurServiceComponent service={service} />
              ))
            }
          </div>
        </div>

        <img
          className={styles.dots}
          src={OurServicesDots}
        />
      </div>
    </div>
  )
};

export default OurServicesComponent;