// extracted by mini-css-extract-plugin
export const hidden = "styles-module--hidden--3hZXd";
export const card = "styles-module--card--3BUR1";
export const illustration = "styles-module--illustration--_iFI3";
export const texts = "styles-module--texts--3g5lG";
export const title = "styles-module--title--17d7z";
export const description = "styles-module--description--2jHhc";
export const subservices = "styles-module--subservices--1z1MV";
export const platforms = "styles-module--platforms--2khbB";
export const list = "styles-module--list--Wwl8t";
export const item = "styles-module--item--98laf";